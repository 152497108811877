import React, { useRef } from 'react';
import Reader from 'react-qr-scanner';
import styles from '../../styles/QRReader.module.scss';

// MAterial UI
import { Close } from '@mui/icons-material';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { Box, Tooltip } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import DialogBox from 'components/Shared/DialogBox';
import { useTranslation } from "react-i18next";

/**
 * 
 * @param props {onScan: {function()}} callback for a succesful scan.
 * @returns 
 */
export default function QRCodeScanner(props: QRCodeScannerProps) {
    const values = ({
        color: props.color || "primary",
        primaryColor: props.primaryColor || "#f6f6f6",
        secondaryColor: props.secondaryColor || "grey.300",
        delay: props.delay || 400
    });
    const { t } = useTranslation('common');
    const [open, setOpen] = React.useState(false);
    const [allowed, setPermission] = React.useState(false);
    const [stream, setStream] = React.useState<MediaStream | undefined>(undefined);
    const [loaded, completeLoading] = React.useState(false);
    const [gotError, hasError] = React.useState(false);
    const initStateGrantPermission: string = t("QRCodeScanner.GrantPermission");
    const [message, setMessage] = React.useState(initStateGrantPermission);
    const [tooltip, setTooltip] = React.useState("");
    const dialog = useRef(null);
    /**
     * Handles opening of the dialog.
     */
    const handleClickOpen = async () => {

        requestPermission();
        setOpen(true);
    };

    const requestPermission = async () => {

        navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } }).then((mediaStream) => {
            if (dialog.current) {
                setPermission(true);
                setStream(mediaStream);
                mediaStream.getTracks().forEach((track) => {
                    let constraints = {
                        aspectRatio: 1
                    };
                    track.applyConstraints(constraints).then(() => {
                    }).catch(err => {
                        console.error(err);
                    })
                })
            }
            else {
                mediaStream.getTracks().forEach((track) => {
                    track.stop();
                });
                setPermission(false);
            }
        }).catch(err => {
            handleError(err);
        });
    }
    /**
     * Handles closing of the dialog.
     */
    const handleClose = () => {
        hasError(false);
        setOpen(false);
        completeLoading(false);
        setMessage(t("QRCodeScanner.LoadingTooltip"));
        setTooltip("");
        stream?.getTracks().forEach((track) => {
            track.stop();
        })
        setStream(undefined);
    };
    /**
     * Handles the scanned data
     * @param data The data that was scanned
     */
    const handleScan = (data: string | null) => {
        // Output data to wherever is needed.
        if (data && props.onScan) {
            props.onScan(data);
            handleClose();
        }
    }

    /**
     * Handles any thrown error
     * @param err The raised error
     */
    const handleError = (err: any) => {

        if (!err) {
            console.error("Invoked error function without error");
            return;
        }
        // Set states
        setMessage(t('QRCodeScanner.NoAccess'));
        hasError(true);
        // Set tooltip depending on message.
        let tooltipTrans = "";
        switch (err.message) {
            case "Permission denied":
                tooltipTrans = t('QRCodeScanner.PermissionDenied');
                break;
            case "Could not start video source":
                tooltipTrans = t('QRCodeScanner.AnotherProcess');
                break;
            case "Permission dismissed":
                tooltipTrans = t('QRCodeScanner.RequestDismissed');
                break;
            default:
                tooltipTrans = t('QRCodeScanner.NoPermission');

        }
        setTooltip(tooltipTrans);
        // Either an error or the permission was denied.
        console.error("Cannot use QR reader: " + tooltipTrans);
        if (err && props.onError) {
            props.onError(err);
        }
    }

    /**
     * Triggers when the camera has loaded.
     */
    const onLoad = () => {
        completeLoading(true);
        setTooltip(t("QRCodeScanner.LoadingTooltip"));
    }

    return (
        <Box id={"QRCodeScanner"}>
            <Tooltip title={t('QRCodeScanner.Tooltip')}>
                <IconButton onClick={handleClickOpen} sx={props.sx}>
                    <QrCodeScannerIcon sx={{ fontSize: 25, color: values.color }} />
                </IconButton>
            </Tooltip>
            <DialogBox innerRef={dialog} aria-labelledby={t("QRCodeScanner.Title")} open={open} onClose={handleClose}>
                <DialogTitle id="QRScannerTitle">
                    {t("QRCodeScanner.Tooltip")}
                    <IconButton onClick={handleClose}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Box bgcolor={values.secondaryColor}
                        className={styles.container}
                    >
                        {/* Dummy is necessary to keep aspect ratio */}
                        <div className={styles.dummy}>
                            {!loaded && !gotError &&
                                <CircularProgress color={'secondary'} className={styles.progress} />}
                        </div>
                        {!loaded &&
                            <Box id={"RequestBox"} className={styles.requestBox} bgcolor={"Grey.300"}>
                                <Typography variant="h5" className={styles.tooltip} color={"secondary"}>
                                    {message}
                                </Typography>
                                <Typography variant="h5" className={styles.tooltip} color={"secondary"}>
                                    {tooltip}
                                </Typography>
                            </Box>
                        }
                        {open && allowed && stream &&
                            <div style={{ opacity: loaded ? 1 : 0 }}>
                                <Reader
                                    delay={values.delay}
                                    onError={handleError}
                                    onScan={handleScan}
                                    onLoad={onLoad}
                                    facingMode={'rear'}
                                    className={styles.video}
                                    initialStream={stream}
                                />
                            </div>
                        }
                    </Box>
                </DialogContent>
            </DialogBox>
        </Box>
    )
}




export interface QRCodeScannerProps {
    //Callbacks
    /**
     * Method to invoke on a succesful scan.
     */
    onScan: (data: string) => void;
    /**
     * Method to invoke when the scanner encounters an error.
     */
    onError?: (err: Error) => void;
    /**
     * The delay between scans in milliseconds. To disable the interval pass in false.
     */
    delay?: number | false;
    camera?: 'front' | 'back';
    color?: string;
    primaryColor?: string | 'primary' | 'secondary';
    secondaryColor?: string | 'primary' | 'secondary';
    sx?: any;
}
